.root {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 20px;
  padding-bottom: 100px;
  padding-left: 10%;
  padding-right: 10%;
}

.h1 {
  font-size: 4.5em; 
  color: black; 
  font-weight: 600;
  text-align: left,
}

.intro {
  display: flex; 
  flex-direction: row; 
  align-items: center;
  padding-top: 50px;
  padding-bottom: 100px;
  justify-content: center;
  height: 65vh;
}

.image {
  width: 800px;
}



/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .intro {
    flex-direction:  column;
    margin-top:  200px;
    align-items:  center;
    justify-content:  center;
  }

  .image {
    width: 300px;
  }

}